import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@progress/kendo-react-buttons';
import { ACCESS_TOKEN, USER_DATA } from '../../../constants/applicationConstants';
import { useAuth } from '../../Core/Context/AuthContext';
import loginOAuth from '../Services/LoginService';
import { MessageAlert } from 'smart-react';

/**
 * LoginForm component for handling user authentication.
 * @param {Object} props - Component props.
 * @param {Function} props.setIsLoader - Function to set loader state.
 * @returns {JSX.Element} Rendered component.
 */
const LoginForm = ({ setIsLoader }) => {
  const { loginWithRedirect, user, isAuthenticated, error } = useAuth0();
  const { checkTokens } = useAuth();
  const dashboardPath = '/';
  const [notification, setNotification] = useState(null);
  // Redirect to dashboard if tokens are present
  useEffect(() => {
    if (checkTokens()) {
      window.location.replace(dashboardPath);
    }
  }, []);

  // Effect to retrieve access token when user is authenticated
  useEffect(() => {
    const fetchAccessToken = async (data) => {
      setIsLoader(true);
      try {
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('email', data.email);
        formData.append('sub', data.sub);
        const response = await loginOAuth(formData);
        const mergedData = { ...data, ...response };

        sessionStorage.setItem(USER_DATA, JSON.stringify(mergedData));
        sessionStorage.setItem(ACCESS_TOKEN, response.payload.token);
        if (sessionStorage.getItem(ACCESS_TOKEN) != 'undefined') {
          window.location.replace(dashboardPath);
        } else {
          setNotification({ type: 'error', message: 'Please try again.' });
          console.error('Error getting access token:', error);
          setIsLoader(false);
        }
      } catch (error) {
        setNotification({ type: 'error', message: 'Please try again.' });
        console.error('Error getting access token:', error);
        setIsLoader(false);
      }
    };

    if (isAuthenticated) {
      fetchAccessToken(user);
    }
    if (error) {
      setNotification({ type: 'error', message: error.message });
    }
  }, [isAuthenticated]);

  const handleLogin = () => {
    setIsLoader(true);
    loginWithRedirect();
  };

  return (
    <>
      {notification && (
        <MessageAlert
          type={notification.type}
          message={notification.message}
          isIcon={true}
          iconName={notification.type}
        />
      )}
      <Button
        className='k-w-100 ai-button-primary'
        onClick={handleLogin}
        rounded={'medium'}
        size={'medium'}
      >
        Login
      </Button>
    </>
  );
};

export default LoginForm;
