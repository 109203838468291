import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { GlobalContext, ErrorFallback, Loader } from 'smart-react';
import { Typography } from '@progress/kendo-react-common';
import logo from '../../../assets/logo/Smart Assitant-09.png';
import logoDark from '../../../assets/logo/Smart Assitant-04.png';
import { DEVICE_TYPES } from '../../../constants/eventDataTypes';

import LoginForm from './LoginForm';
import './Splash.scss';

/**
 * Main Login Screen
 * @returns {React.Element} returns main login screen
 */
const Splash = () => {
  const { dimensions, deviceInfo } = React.useContext(GlobalContext);
  const [isMobile, setIsMobile] = React.useState(null);
  const [isLoader, setIsLoader] = React.useState(false);
  const loginGridClassName = `login-grid`;

  React.useEffect(() => {
    if (dimensions !== undefined) {
      if (
        deviceInfo?.type === DEVICE_TYPES.PHONE ||
        deviceInfo?.type === DEVICE_TYPES.TABLET
      ) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
  }, [dimensions]);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <>
        {isLoader && (
          <div className="loader-wrapper">
            <Loader />
          </div>
        )}
        {isMobile !== null && (
          <>
            {!isMobile ? (
              <div className={loginGridClassName}>
                <div className="login-rside">
                  <img
                    src={logo}
                    className="login-logo"
                    alt={`${process.env.APP_NAME} Logo`}
                  />
                </div>

                <div className="login-fside">
                  <div className="form-container">
                    <Typography.h6
                      className="k-font-bold"
                      fontWeight="bold"
                      textAlign="center"
                    >
                      Let's Sign You In.
                    </Typography.h6>

                    <Typography.p textAlign="center">
                      To Continue, first Verify that it's You.
                    </Typography.p>
                    <LoginForm isLoader={isLoader} setIsLoader={setIsLoader} />
                  </div>
                </div>
              </div>
            ) : (
              <div className="login-grid-mobile">
                <div className="login-mobile">
                  <img
                    src={logoDark}
                    className="login-logo-mobile"
                    alt={`${process.env.APP_NAME} Logo`}
                  />
                  <div className="form-container-mobile">
                    <Typography.h6
                      className="k-font-bold"
                      fontWeight="bold"
                      textAlign="center"
                    >
                      Let's Sign You In.
                    </Typography.h6>

                    <Typography.p textAlign="center">
                      To Continue, first Verify that it's You.
                    </Typography.p>
                    <LoginForm isLoader={isLoader} setIsLoader={setIsLoader} />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </>
    </ErrorBoundary>
  );
};

export default Splash;
