import { callAPI } from '../../../Utils/Api/Api';

/**
 * Base URL for OpenAI API.
 * @constant {string}
 */

const APP_BASE_URL = `${process.env.APP_BASE_URL}`;

/**
 * Login OAuth
 * @async
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while uploading the file.
 * @param data
 */
const loginOAuth = async (data) => {
  try {
    return await callAPI(`${APP_BASE_URL}/loginOAuth`, 'POST', data);
  } catch (error) {
    throw new Error(`Error While Login: ${error.message}`);
  }
};

export default loginOAuth;
